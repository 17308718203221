import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { IoIosArrowDown } from "react-icons/io";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoMdClose } from "react-icons/io";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { FaExpand } from "react-icons/fa";
import { FACILITY_IMAGE_CDN } from "../../../src/app/components/shared/urlConstants";
import defaultFacilityImage from "../../../src/assets/images/cv-hospital-illustration.jpg";
import trustimg from "../../../src/assets/images/trustmark4.svg";
import "../Maps/SearchPageMap.css";

// Fix for default marker icons not displaying
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl:
    "data:image/svg+xml;charset=UTF-8," +
    encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="50" viewBox="0 0 35 50">
      <path fill="#0f4d92" stroke="#003366" stroke-width="1" d="M17.5 0C7.8 0 0 7.8 0 17.5c0 11.4 17.5 35 17.5 35s17.5-23.6 17.5-35C35 7.8 27.2 0 17.5 0z"/>
      <circle cx="17.5" cy="15" r="6.5" fill="#e5803c" stroke="#ffffff" stroke-width="2"/>
    </svg>
  `),
  iconSize: [22, 37],
  iconAnchor: [11, 37],
  popupAnchor: [0, -37],
});

// Modal style for fullscreen map
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxWidth: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "12px",
  outline: "none",
  height: { xs: "50%", sm: "80%" },
};

// Utility function to fetch treatment data from local storage
const getTreatmentFromLocalStorage = () => {
  const storedData = localStorage.getItem("cv-treatment-app");
  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing localStorage data", error);
    }
  }
  return {
    treatment: "",
    treatmentId: "",
    subTreatment: "",
    subTreatmentId: "",
  };
};

const FitMapToBounds = ({ locations }) => {
  const map = useMap();

  useEffect(() => {
    if (locations.length > 0) {
      const bounds = locations.map((loc) => loc.position);
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [locations, map]);

  return null;
};

const SearchPageMap = ({ facilities }) => {
  console.log(facilities);
  const navigate = useNavigate();
  const [treatmentData, setTreatmentData] = useState({
    treatment: "",
    treatmentId: "",
    subTreatment: "",
    subTreatmentId: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch treatment data on component mount
  useEffect(() => {
    const data = getTreatmentFromLocalStorage();
    setTreatmentData(data);
  }, []);

  const getURLFromString = (str) =>
    str?.toLowerCase().replace(/[^a-z0-9]+/g, "-");

  const handleCardClick = (facility) => {
    if (treatmentData.treatment) {
      navigate(
        `/location/${getURLFromString(
          facility.country_name
        )}/${getURLFromString(facility.city_name)}/${getURLFromString(
          facility.facility_name
        )}?service=${getURLFromString(treatmentData.treatment)}`
      );
    }
  };

  const locations = facilities.map((facility) => ({
    facility_id: facility._id,
    city_name: facility.city_name,
    country_name: facility.country_name,
    address: facility.address,
    position: [parseFloat(facility.latitude), parseFloat(facility.longitude)],
    facility_name: facility.facility_name,
    curevalue_rating: facility.curevalue_rating,
    first_image: facility.image_urls?.[0] || null,
  }));

  const center = [20, 20];
  const zoom = 1;

  useEffect(() => {
    if (locations.length > 0) {
      setLoading(false);
    }
  }, [locations]);

  const politicalTileLayer =
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  return (
    <div className="relative z-0 rounded-lg">
      {/* This is the modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="map-modal-title"
        aria-describedby="map-modal-description"
      >
        <Box sx={{ ...modalStyle, position: "relative" }}>
          <IoMdClose
            onClick={() => setIsModalOpen(false)}
            className="absolute top-0 right-[-5px] cursor-pointer text-[34px]"
            style={{ zIndex: "5" }}
          >
            ✕
          </IoMdClose>

          <MapContainer
            center={center}
            zoom={zoom}
            className="w-full h-full rounded-lg"
            zoomControl={true}
          >
            <TileLayer url={politicalTileLayer} opacity={0.8} />
            <FitMapToBounds locations={locations} />
            {loading ? (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 text-lg font-bold text-black text-opacity-100">
                Loading cities...
              </div>
            ) : (
              <MarkerClusterGroup>
                {locations.map(
                  (
                    {
                      city_name,
                      country_name,
                      position,
                      facility_name,
                      first_image,
                      address,
                      curevalue_rating,
                    },
                    index
                  ) => (
                    <Marker key={index} position={position} icon={customIcon}>
                      <Popup
                        className="font-semibold text-sm p-0"
                        closeOnClick={false}
                        autoPan={false} // Prevents auto panning if needed
                      >
                        <div
                          className="flex flex-col items-start bg-white border-[3px] border-[#fe6a36] rounded-[30px] shadow-lg overflow-hidden"
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            width: window.innerWidth <= 600 ? "200px" : "250px", // Adjust width for mobile view
                          }}
                        >
                          <div
                            className="relative w-full bg-gray-100 rounded-t-[30px]"
                            style={{
                              height:
                                window.innerWidth <= 600 ? "90px" : "120px",
                            }} // Adjust height for mobile view
                          >
                            <img
                              src={
                                first_image
                                  ? `${FACILITY_IMAGE_CDN}${facilities[index]._id}/${first_image}`
                                  : defaultFacilityImage
                              }
                              alt={`${facility_name}`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = defaultFacilityImage;
                              }}
                              className="absolute inset-0 w-full h-full object-cover rounded-t-[30px]"
                            />
                          </div>
                          <div className="p-[10px]">
                            <div>
                              <p
                                className="block text-ellipsis overflow-hidden whitespace-nowrap"
                                style={{
                                  marginTop:
                                    window.innerWidth <= 600 ? "-1px" : "-3px",
                                  maxWidth: "170px",
                                }}
                              >
                                {facility_name}
                              </p>
                            </div>
                            <p
                              className="font-medium block text-ellipsis overflow-hidden"
                              style={{
                                maxWidth:
                                  window.innerWidth <= 600 ? "180px" : "224px",
                                marginTop:
                                  window.innerWidth <= 600 ? "-10px" : "-18px",
                              }}
                            >
                              {address}
                            </p>
                            <div
                              className="flex justify-between gap-[20px]"
                              style={{
                                gap: window.innerWidth <= 600 ? "20px" : "36px",
                              }}
                            >
                              <p className="text-sm text-gray-600 mb-2">
                                {city_name}, {country_name}
                              </p>
                              <div
                                className="flex items-center gap-[10px]"
                                style={{
                                  gap:
                                    window.innerWidth <= 600 ? "8px" : "13px",
                                }}
                              >
                                <div className="relative flex items-center justify-center">
                                  <img
                                    src={trustimg}
                                    alt="CureValue Rating"
                                    className="w-full h-auto"
                                  />
                                  <p className="absolute top-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-semibold">
                                    {Math.round(parseFloat(curevalue_rating))}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <span
                              className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
                              onClick={() =>
                                handleCardClick({
                                  country_name,
                                  city_name,
                                  facility_name,
                                })
                              }
                              style={{
                                fontSize:
                                  window.innerWidth <= 600 ? "12px" : "inherit",
                              }}
                            >
                              View more
                            </span>
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                  )
                )}
              </MarkerClusterGroup>
            )}
          </MapContainer>
        </Box>
      </Modal>

      <div className="relative rounded-lg shadow-lg overflow-hidden border border-gray-300 bg-white">
        <div className="flex items-center justify-between px-4 py-3 bg-white bg-opacity-90 border-b border-gray-300 shadow-lg rounded-t-lg">
          <h3 className="text-[22px] font-medium text-black">View in map</h3>
          <div className="flex items-center space-x-3">
            <button onClick={() => setIsModalOpen(true)}>
              <FaExpand className="text-black text-lg" />
            </button>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="p-1 transition-transform duration-300"
            >
              <IoIosArrowDown
                className={`text-black text-xl transform transition-transform duration-300 ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
        </div>
        <div
          className={`${
            isDropdownOpen
              ? "max-h-[276px] opacity-100 scale-100"
              : "max-h-0 opacity-0 scale-95"
          } transition-all duration-500 ease-in-out overflow-hidden`}
        >
          <MapContainer
            center={center}
            zoom={zoom}
            className="w-full h-[250px] md:h-[200px] lg:h-[276px] xl:h-[276px] rounded-b-lg"
            zoomControl={false}
          >
            <TileLayer url={politicalTileLayer} opacity={0.8} />
            {loading ? (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 text-lg font-bold text-black text-opacity-100">
                Loading cities...
              </div>
            ) : (
              <MarkerClusterGroup>
                {locations.map(
                  (
                    {
                      city_name,
                      country_name,
                      position,
                      facility_name,
                      first_image,
                      address,
                      curevalue_rating,
                    },
                    index
                  ) => (
                    <Marker key={index} position={position} icon={customIcon}>
                      <Popup
                        className="font-semibold text-sm p-0"
                        closeOnClick={false}
                      >
                        <div
                          className="flex flex-col bg-white border-[2px] border-[#fe6a36] rounded-[15px] shadow-md overflow-hidden"
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            width: window.innerWidth <= 600 ? "150px" : "200px", // Reduced width for better fit
                          }}
                        >
                          <div
                            className="flex items-center bg-gray-100 p-1 gap-[12px]" // Adjusted padding and gap
                            style={{
                              padding:
                                window.innerWidth <= 600 ? "6px" : "10px",
                            }}
                          >
                            <div
                              className="relative flex-shrink-0"
                              style={{ width: "50px", height: "50px" }} // Reduced image size
                            >
                              <img
                                src={
                                  first_image
                                    ? `${FACILITY_IMAGE_CDN}${facilities[index]._id}/${first_image}`
                                    : defaultFacilityImage
                                }
                                alt={`${facility_name}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = defaultFacilityImage;
                                }}
                                className="w-full h-full object-cover rounded-md"
                              />
                            </div>
                            <p className="text-black font-medium truncate">
                              {facility_name}
                            </p>
                          </div>
                          <div className="px-1 py-1">
                            {" "}
                            {/* Adjusted padding */}
                            <div className="flex justify-between h-[40px]">
                              <p className="text-gray-600 text-xs">
                                {" "}
                                {/* Smaller text size */}
                                {city_name}, {country_name}
                              </p>
                              <div
                                className="flex items-center gap-[10px]"
                                style={{
                                  gap:
                                    window.innerWidth <= 600 ? "8px" : "13px",
                                }}
                              >
                                <div className="relative flex items-center justify-center">
                                  <img
                                    src={trustimg}
                                    alt="CureValue Rating"
                                    className="w-[90%] h-[90%]"
                                  />
                                  <p className="absolute top-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-semibold">
                                    {Math.round(parseFloat(curevalue_rating))}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* View more button */}
                            <button
                              onClick={() =>
                                handleCardClick({
                                  country_name,
                                  city_name,
                                  facility_name,
                                })
                              }
                              className="mt-1 text-xs text-blue-600 underline cursor-pointer"
                            >
                              View more
                            </button>
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                  )
                )}
              </MarkerClusterGroup>
            )}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default SearchPageMap;
