import { random } from "lodash";
import CityLocation from "../Maps/CityLocation";
import { useEffect, useState } from "react";
import CVPopup from "../shared/CVPopup";
import {
  FaCity,
  FaDollarSign,
  FaLanguage,
  FaTimes,
  FaVideo,
} from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoSearch } from "react-icons/io5";
import { LiaCitySolid } from "react-icons/lia";
import { VscSettings } from "react-icons/vsc";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { PiMedalFill } from "react-icons/pi";
import { TbLicense } from "react-icons/tb";
import { deConstructURLSegment } from "../../../helpers/CVHelper";
import { RiExpandLeftRightLine } from "react-icons/ri";

const CitySelection = ({
  selectedTreatment,
  selectedCity,
  selectedCountry,
  allCities,
  setSelectedCity,
  isVisaRequired,
  setCitySelectionPopup,
  allSelectedCities,
  removeCityFromAllSelectedCities,
  setVirtualVisits,
  virtualVisits,
  allLanguages,
  selectedLanguages,
  setSelectedLanguages,
  removeAllLanguages,
  allVisaRequirements,
  setSelectedVisaRequirement,
  selectedVisaRequirement,
  costOfLiving,
  accreditations,
  handleClearSelection,
  citySelectionPopup,
  filteredCities,
}: any) => {
  // const [citySelectionPopup, setCitySelectionPopup] = useState(true);
  const [mobileFilterSection, setMobileFilterSection] = useState(false);
  const [visaRequired, setVisarequired] = useState(true);

  const handleSelectedCity = (event: any) => {
    const cityName = event.target.value;
    const city = allCities.find((city: any) => city.city_name === cityName);
    setSelectedCity(city);
  };

  const normalizeAllSelectedCities = (cities: any) => {
    if (cities instanceof Set) {
      return Array.from(cities);
    }
    if (typeof cities === "object" && cities !== null) {
      return Object.values(cities).flatMap((set) =>
        Array.from(set as Set<string>)
      );
    }
    return [];
  };

  const normalizedCities = normalizeAllSelectedCities(filteredCities);

  // const handleLanguageChange = (language: any) => {
  //   setSelectedLanguages((prevSelected: any) => {
  //     if (prevSelected.includes(language.language)) {
  //       // Remove if already selected
  //       return prevSelected.filter((item: any) => item !== language.language);
  //     } else {
  //       // Add if not selected
  //       return [...prevSelected, language.language];
  //     }
  //   });
  //   // openFilter();
  // };

  const handleLanguageChange = (language: { language: string }) => {
  setSelectedLanguages((prevSelected: string[]) => {
    // If the language is already selected, deselect it (allows unchecking the only selected checkbox)
    if (prevSelected.includes(language.language)) {
      return [];
    } else {
      // Only one checkbox can be selected, so set the selected language as a single-element array
      return [language.language];
    }
  });
};

  const handleVisaRequirementChange = () => {
    // setSelectedVisaRequirement(requirement);
    setVisarequired(!visaRequired);
  };

  useEffect(() => {
    if (visaRequired) {
      setSelectedVisaRequirement(null);
    } else {
      setSelectedVisaRequirement("Not Required");
    }
  }, [visaRequired]);

  const removeVisaRequirement = () => {
    setSelectedVisaRequirement(null);
  };

  return (
    <>
      {/* desktop view start */}
      <div className="hidden lg:block p-3 border-b border-b-greyclr z-50">
        <div className="flex gap-[10px]">
          <GiSettingsKnobs size={30} />
          <h1 className="text-h3font lg:text-[24px] font-semibold pb-[10px] lg:pb-[0]">
            Filters
          </h1>
        </div>
      </div>

      <div className="hidden lg:block mb-[22px] pt-10 pb-[21px] p-3  lg:border-b lg:border-borderclr flex flex-col gap-5 border-b">
        {/* this block is hidden for large devices */}

        <div className="block lg:hidden">
          <h1 className="text-h3font lg:text-[24px] font-semibold pb-[10px] lg:pb-[0]">
            {selectedTreatment} in
          </h1>
          {/* <select
          className="hidden lg:block font-semibold text-h4font lg:text-[24px] text-iconclr bg-transparent appearance-none w-[100%] 2xl:w-[85%] selectcity cursor-pointer border border-[#c4cad3] lg:border-none lg:outline-none py-[6px] px-[17px] lg:px-[0] lg:py-[0] rounded-[10px] cityselect-input"
          onChange={handleSelectedCity}
        >
          {allCities.map((city: any) => {
            return (
              <>
                <option key={city.city_name} value={city.city_name}>
                  {city.city_name}
                </option>
              </>
            );
          })}
        </select> */}
          {/* <select
            className=" text-pretty block font-semibold text-h4font lg:text-[24px] text-iconclr bg-transparent appearance-none w-[100%] 2xl:w-[85%] selectcity cursor-pointer border border-[#c4cad3] lg:border-none lg:outline-none py-[6px] px-[17px] lg:px-[0] lg:py-[0] rounded-[10px] cityselect-input"
            onChange={handleSelectedCity}
          >
            {allCities.map((city: any) => {
              return (
                <option
                  key={Math.random()}
                  value={city.city_name}
                  selected={selectedCity.city_name == city.city_name}
                >
                  {city.city_name}, {selectedCountry.country_name}
                </option>
              );
            })}
          </select> */}
          {/* <p className="font-semibold lg:text-[24px] pt-3 hidden lg:block">
          {selectedCountry.country_name}
        </p> */}
        </div>

        <div
          className={`xl:shadow-cardshadow citycard lg:border lg:border-borderclr rounded-[15px] pb-[10px] ${
            Array.from(allSelectedCities).length > 0 ? "" : ""
          } px-3`}
        >
          <div
            className="flex items-center cursor-pointer justify-start gap-[10px]"
            onClick={() => {
              setCitySelectionPopup(!citySelectionPopup);
            }}
          >
            {/* <FaCity size={50} /> */}
            <LiaCitySolid size={30} />
            <button
              type="button"
              id="kt_sign_in_submit"
              className=" lg:text-[18px] text-sc text-h3font font-semibold py-[14px] cursor-pointer group  hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
            >
              City selection
            </button>

            {citySelectionPopup ? (
              <RiExpandLeftRightLine size={30} className="ml-auto" />
            ) : (
              <IoSearch size={30} className="ml-auto" />
            )}
          </div>

          {/* applied filter div start */}
          <div className="flex lg:ms-[0px] mt-[5px]">
            <div className="hidden lg:block ms-[0px]">
              <div className="flex flex-wrap gap-3 pb-[10px]">
                {normalizedCities.map((selectedCity: string) => {
                  let cityName = deConstructURLSegment(selectedCity);
                  const capitalizedCity =
                    cityName.charAt(0).toUpperCase() + cityName.slice(1);

                  if (!capitalizedCity.trim()) {
                    return null; // Skip rendering if the city name is blank
                  }

                  return (
                    <div
                      key={selectedCity}
                      className="flex items-center gap-2 bg-gray-300 py-[5px] px-[5px] rounded"
                    >
                      <p>{capitalizedCity}</p>
                      <button
                        onClick={() => {
                          removeCityFromAllSelectedCities(selectedCity);
                        }}
                        className="focus:outline-none"
                      >
                        <FaTimes className="text-gray-500" />
                      </button>
                    </div>
                  );
                })}
              </div>
              {normalizedCities.length > 1 ? (
                <>
                  <span
                    className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
                    onClick={handleClearSelection}
                  >
                    Clear all
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* applied filter div end */}
        </div>

        {/* <div className="">
          <div className="flex justify-between items-center">
            <h3 className="text-sc text-[20px] font-semibold pb-[21px]">
              Travel Visa
            </h3>
          </div>
          <div>
            {allVisaRequirements.map((requirement: any) => {
              return (
                <div
                  key={requirement.id}
                  className="flex items-start pb-[18px]"
                >
                  <input
                    type="radio"
                    name="visaRequirement"
                    value={requirement.requirement}
                    className="mr-[10px] w-[20px] h-[20px]"
                    checked={
                      selectedVisaRequirement === requirement.requirement
                    }
                    onChange={() =>
                      handleVisaRequirementChange(requirement.requirement)
                    }
                  />
                  <label className="text-anchortext font-medium">
                    {requirement.requirement}
                  </label>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="rounded-[15px] xl:shadow-cardshadow citycard lg:border lg:border-borderclr mt-[15px] px-[10px] py-[10px]">
          <div className="flex items-start pb-[0px]">
            <TbLicense size={30} />
            <div className="flex flex-col ml-[10px]">
              <label className="lg:text-[18px] text-sc text-h3font font-semibold cursor-pointer group disabled:opacity-75 disabled:cursor-not-allowed">
                Visa requirement
              </label>
              <label className="">
                {visaRequired ? "Show all cities" : "Cities not requiring visa"}
              </label>
            </div>
            <input
              type="checkbox"
              id="switch"
              checked={visaRequired}
              onChange={() => handleVisaRequirementChange()}
              className="switch ml-auto"
            />
          </div>
        </div>

        {/* language selection section start*/}

        <div className="xl:shadow-cardshadow citycard lg:border lg:border-borderclr rounded-[15px] px-[10px] mt-[15px]">
          <div className="flex mt-[5px]">
            <FaLanguage size={30} />
            <div className="flex flex-col mt-[0px] ml-[10px]">
              {/* <h3 className="text-gray-500 text-[20px] font-semibold pb-[21px]">
                Language spoken
              </h3> */}
              <label className="lg:text-[18px] text-sc text-h3font font-semibold cursor-pointer group disabled:opacity-75 disabled:cursor-not-allowed pb-[16px]">
                Language spoken
              </label>

              {/* <p
              className={`lg:text-anchortext font-medium ${
                selectedLanguages.length > 0
                  ? "underline text-[#006AF5] cursor-pointer"
                  : "underline"
              }`}
              onClick={() => {
                if (selectedLanguages.length > 0) {
                  removeAllLanguages();
                }
              }}
            >
              Clear
            </p> */}

              <div>
                {allLanguages.map((language: any) => {
                  return (
                    <div
                      key={language.language}
                      className="flex items-start pb-[18px]"
                    >
                      <input
                        type="checkbox"
                        name="language"
                        className="mr-[10px] w-[20px] h-[20px]"
                        checked={
                            language.language === "English" ||
                            selectedLanguages.includes(language.language)
                        }
                        onChange={() => handleLanguageChange(language)}
                        // disabled={true} // This disables the checkbox
                      />
                      <label className="text-anchortext">
                        {language.language}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* language selection section end*/}

        <div
          className={`xl:shadow-cardshadow citycard lg:border lg:border-borderclr rounded-[15px] px-3 mt-[15px]`}
        >
          <div
            className="flex items-center text-gray-500 justify-start gap-[10px]"
            // onClick={() => {
            //   setVirtualVisits(!virtualVisits);
            // }}
          >
            {/* <FaCity size={50} /> */}
            <FaVideo size={30} />
            {/* <button
              type="button"
              id="kt_sign_in_submit"
              className="lg:text-[18px] text-gray-500 text-h3font font-semibold py-[14px] group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
            >
              Virtual visits
            </button> */}
            <label className="lg:text-[18px] py-[14px] text-gray-500 text-h3font pb-[21px] font-semibold cursor-pointer group disabled:opacity-75 disabled:cursor-not-allowed">
              Virtual visits
            </label>

            {/* <TiTick size={30} className="ml-auto" /> */}
          </div>
        </div>

        {/* <div>
          <div className="d-grid ps-[20px] mb-[5px] text-gray-500 w-[100%] border border-pc flex flex-col justify-center gap-[10px]">
            <div
              className="flex items-center gap-[20px]"
              // onClick={() => {
              //   setVirtualVisits(!virtualVisits);
              // }}
            >
              <FaVideo size={50} />
              <button
                type="button"
                id="kt_sign_in_submit"
                className="mt-[10px] text-gray-500 lg:text-[18px] text-pfont font-medium py-[14px] group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
              >
                Virtual Visits
              </button>
              {virtualVisits ? (
                <>
                  <TiTick size={30} className="tick-icon" />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}

        {/* cost of living selection section start*/}

        <div className="xl:shadow-cardshadow citycard lg:border lg:border-borderclr rounded-[15px] py-[10px] px-[10px] mt-[15px]">
          <div className="flex text-gray-500">
            <FaDollarSign size={30} />
            <div className="flex flex-col mt-[0px] justify-between items-center">
              {/* <h3 className="text-gray-500 text-[20px] font-semibold pb-[21px]">
                Cost of living
              </h3> */}
              <label className="lg:text-[18px] text-gray-500 text-h3font pb-[21px] font-semibold cursor-pointer group   disabled:opacity-75 disabled:cursor-not-allowed">
                Cost of living
              </label>

              <div>
                {costOfLiving.map((livingCost: any) => {
                  return (
                    <div
                      key={livingCost.id}
                      className="flex items-start pb-[18px]"
                    >
                      <input
                        type="checkbox"
                        name="livingCost"
                        className="mr-[10px] w-[20px] h-[20px]"
                        // checked={selectedLanguages.includes(livingCost.livingCost)}
                        // onChange={() => handleLanguageChange(livingCost)}
                        disabled={true} // This disables the checkbox
                      />
                      <label className="text-anchortext">
                        {livingCost.livingCost}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* cost of living selection section end*/}

        {/* accreditations selection section start*/}

        <div className="xl:shadow-cardshadow citycard lg:border lg:border-borderclr rounded-[15px] px-[10px] py-[10px] mt-[15px]">
          <div className="flex text-gray-500">
            <PiMedalFill size={30} />
            <div className="flex flex-col mt-[0px] justify-between">
              {/* <h3 className="text-gray-500 text-[20px] font-semibold pb-[21px]">
                Accreditations
              </h3> */}
              <label className="lg:text-[18px] text-gray-500 text-h3font pb-[21px] font-semibold cursor-pointer group  disabled:opacity-75 disabled:cursor-not-allowed">
                Accreditations
              </label>

              <div>
                {accreditations.map((accreditation: any) => {
                  return (
                    <div
                      key={accreditation.id}
                      className="flex items-start pb-[18px]"
                    >
                      <input
                        type="checkbox"
                        name="livingCost"
                        className="mr-[10px] w-[20px] h-[20px]"
                        // checked={selectedLanguages.includes(
                        //   accreditation.accreditation
                        // )}
                        // onChange={() => handleLanguageChange(livingCost)}
                        disabled={true} // This disables the checkbox
                      />
                      <label className="text-anchortext">
                        {accreditation.accreditation}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* accreditations selection section end*/}

        {/* <div className="w-full">
        <CityLocation selectedCity={selectedCity} />
      </div> */}
        {/* <div>
          {selectedCity.visa_required == true ? (
            <>
              <h2 className="text-[20px] text-gray-500">
                Travel Visa:{" "}
                <strong className="pl-1 text-gray-500">Required</strong>
              </h2>
            </>
          ) : (
            <>
              <h2 className="text-[20px] text-gray-500">
                Travel Visa:
                <strong className="pl-1 text-gray-500">Not required</strong>
              </h2>
            </>
          )}
        </div> */}
        {/* <div className="hidden lg:block">
                <select className="font-semibold text-[21px] text-greyclr bg-transparent appearance-none w-[75%] selectcity cursor-pointer" style={{ outline: 'none', border: 'none' }}>
                    <option>3 Months - 6 Months</option>
                    <option>3 Months - 6 Months</option>
                    <option>3 Months - 6 Months</option>
                    <option>3 Months - 6 Months</option>
                </select>
            </div> */}
      </div>

      {/* desktop view end */}

      {/* ====================================================================================================================================== */}

      {/* mobile view start */}
      <div className="lg:hidden flex justify-end mt-[20px]">
        <div
          className="flex gap-[5px]"
          onClick={() => {
            setMobileFilterSection(true);
          }}
        >
          <GiSettingsKnobs />

          <h1 className="text-[16px] font-medium pb-[10px]">Filters</h1>
        </div>
      </div>

      {mobileFilterSection ? (
        <>
          <div className="lg:hidden fixed z-50 top-0 left-0 right-0 bottom-0 bg-white">
            {/* Fixed header */}
            <div className="flex items-center fixed z-160 ps-[10px] h-[60px] w-[100vw] shadow-lg">
              <MdOutlineKeyboardBackspace
                size={30}
                onClick={() => {
                  setMobileFilterSection(false);
                }}
              />
            <span className="font-bold ms-[10px]" onClick={() => {
                  setMobileFilterSection(false);
                }}>Show results</span>
            </div>

            {/* Scrollable content start*/}
            <div className="px-[10px] py-[10px]">
              <div className="mt-[60px] overflow-y-auto h-[calc(100vh-60px)]">
                {/* city selection filter for mobile start */}
                <div
                  className={`pb-[10px] shadow-cardshadow citycard border border-borderclr rounded-[15px] px-[10px] mt-[15px] ${
                    Array.from(allSelectedCities).length > 0
                      ? "border-pc"
                      : "border-sc"
                  } px-3 mt-[10px]`}
                >
                  <div
                    className="flex items-center cursor-pointer justify-start gap-[10px]"
                    onClick={() => {
                      setCitySelectionPopup(true);
                    }}
                  >
                    {/* <FaCity size={50} /> */}
                    <LiaCitySolid size={30} />
                    <button
                      type="button"
                      id="kt_sign_in_submit"
                      className="text-pc lg:text-[18px] text-sc text-h3font font-semibold py-[14px] cursor-pointer group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
                    >
                      City selection
                    </button>

                    {citySelectionPopup ? (
                      <RiExpandLeftRightLine size={30} className="ml-auto" />
                    ) : (
                      <IoSearch size={30} className="ml-auto" />
                    )}
                  </div>
                  {/* applied filter div start */}
                  <div className="flex lg:ms-[0px] mt-[5px]">
                    <div className="block lg:hidden ms-[0px]">
                      <div className="flex flex-wrap gap-3 pb-[10px]">
                        {normalizedCities.map((selectedCity: string) => {
                          let cityName = deConstructURLSegment(selectedCity);
                          const capitalizedCity =
                            cityName.charAt(0).toUpperCase() +
                            cityName.slice(1);

                          if (!capitalizedCity.trim()) {
                            return null; // Skip rendering if the city name is blank
                          }

                          return (
                            <div
                              key={selectedCity}
                              className="flex items-center gap-2 bg-gray-300 py-[5px] px-[5px] rounded"
                            >
                              <p>{capitalizedCity}</p>
                              <button
                                onClick={() => {
                                  removeCityFromAllSelectedCities(selectedCity);
                                }}
                                className="focus:outline-none"
                              >
                                <FaTimes className="text-gray-500" />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      {normalizedCities.length > 1 ? (
                        <>
                          <span
                            className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
                            onClick={handleClearSelection}
                          >
                            Clear all
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* applied filter div end */}
                </div>
                {/* city selection filter for mobile end */}

                {/* travel visa mobile section start */}
                {/* <div className="px-[10px] mt-[10px]">
                <div className="flex justify-between items-center">
                  <h3 className="text-sc text-[20px] font-semibold pb-[21px]">
                    Travel Visa
                  </h3>
                </div>
                <div>
                  {allVisaRequirements.map((requirement: any) => {
                    return (
                      <div
                        key={requirement.id}
                        className="flex items-start pb-[18px]"
                      >
                        <input
                          type="radio"
                          name="visaRequirement"
                          value={requirement.requirement}
                          className="mr-[10px] w-[20px] h-[20px]"
                          checked={
                            selectedVisaRequirement === requirement.requirement
                          }
                          onChange={() =>
                            handleVisaRequirementChange(requirement.requirement)
                          }
                        />
                        <label className="text-anchortext font-medium">
                          {requirement.requirement}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div> */}
                {/* travel visa mobile section end */}

                <div className="shadow-cardshadow citycard border border-borderclr rounded-[15px] mt-[15px] px-[10px] py-[10px]">
                  <div className="flex items-start pb-[0px]">
                    <TbLicense size={30} />
                    <div className="flex flex-col ms-[10px]">
                      <label className="text-sc text-[20px] font-semibold">
                        Travel visa
                      </label>
                      <label className="">
                        {visaRequired ? "Show All" : "No Visa Required"}
                      </label>
                    </div>
                    <input
                      type="checkbox"
                      id="switch"
                      checked={visaRequired}
                      onChange={() => handleVisaRequirementChange()}
                      className="switch ml-auto"
                    />
                  </div>
                </div>

                {/* language selection section start */}
                <div className="shadow-cardshadow citycard border border-borderclr rounded-[15px] px-[10px] mt-[15px]">
                  <div className="flex">
                    <FaLanguage size={30} />
                    <div className="flex flex-col justify-between ms-[10px]">
                      <h3 className="text-sc text-[20px] font-semibold pb-[21px]">
                        Language spoken
                      </h3>

                      <div>
                        {allLanguages.map((language: any) => {
                          return (
                            <div
                              key={language.language}
                              className="flex items-start pb-[18px]"
                            >
                              <input
                                type="checkbox"
                                name="language"
                                className="mr-[10px] w-[20px] h-[20px]"
                                checked={
                                  language.language === "English" ||
                                  selectedLanguages.includes(language.language)
                                }
                                onChange={() => handleLanguageChange(language)}
                                // disabled={true} // This disables the checkbox
                              />
                              <label className="text-anchortext">
                                {language.language}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* language selection section end */}

                {/* virtual visits section start */}
                <div className="shadow-cardshadow citycard border border-borderclr rounded-[15px] px-[10px] mt-[15px]">
                  <div className="">
                    <div className="flex items-center text-gray-500 justify-start gap-[10px]">
                      {/* <FaCity size={50} /> */}
                      <FaVideo size={30} />
                      <button
                        type="button"
                        id="kt_sign_in_submit"
                        className="lg:text-[18px] text-gray-500 text-h3font font-semibold py-[14px] group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                      >
                        Virtual visits
                      </button>
                    </div>
                  </div>
                </div>
                {/* virtual visits section end */}

                {/* cost of living selection section start */}
                <div className="shadow-cardshadow citycard border border-borderclr rounded-[15px] px-[10px] mt-[15px]">
                  <div className="flex text-gray-500 mt-[5px]">
                    <FaDollarSign size={30} />
                    <div className="flex flex-col ms-[10px] justify-between">
                      <h3 className="text-gray-500 text-[20px] font-semibold pb-[21px]">
                        Cost of living
                      </h3>

                      <div>
                        {costOfLiving.map((livingCost: any) => {
                          return (
                            <div
                              key={livingCost.id}
                              className="flex items-start pb-[18px]"
                            >
                              <input
                                type="checkbox"
                                name="livingCost"
                                className="mr-[10px] w-[20px] h-[20px]"
                                disabled={true} // This disables the checkbox
                              />
                              <label className="text-anchortext">
                                {livingCost.livingCost}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* cost of living selection section end */}

                {/* accreditations selection section start */}
                <div className="shadow-cardshadow citycard border border-borderclr rounded-[15px] px-[10px] mt-[15px] mb-[20px]">
                  <div className="flex text-gray-500 mt-[5px]">
                    <PiMedalFill size={30} />
                    <div className="flex flex-col ms-[10px] justify-between">
                      <h3 className="text-gray-500 text-[20px] font-semibold pb-[21px]">
                        Accreditations
                      </h3>

                      <div>
                        {accreditations.map((accreditation: any) => {
                          return (
                            <div
                              key={accreditation.id}
                              className="flex items-start pb-[18px]"
                            >
                              <input
                                type="checkbox"
                                name="livingCost"
                                className="mr-[10px] w-[20px] h-[20px]"
                                disabled={true} // This disables the checkbox
                              />
                              <label className="text-anchortext">
                                {accreditation.accreditation}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* accreditations selection section end */}
              </div>
            </div>
            {/* Scrollable content end*/}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CitySelection;
